<template>
    <div class="col" style="height: calc(100vh - 180px)">
        <div class="content-mac row h-100 custom-scroll overflow-auto">
            <perfil-mac :user="user" editar @editar="verEditar" />
            <div v-if=" !userSeleccionado" class="col-6 content-mac__vincular">
                <button
                class="bg-red px-4 py-2 border-0 br-12 shadow cr-pointer text-white"
                style="outline:0"
                @click="actionVincular"
                >
                    Vincular 
                </button>
            </div>
            <perfil-mac v-else :user="userSeleccionado" editar desvincular @desvincularUsuario="desvincularUsuario" />
        </div>
        <modal-vincular-usuario ref="modalVincular" @VincularUsuario="VincularUsuario" />
    </div>
</template>

<script>
import ModalVincularUsuario from '../../partials/modalVincularUsuario.vue'
import modalVincularUsuarioVue from '../../partials/modalVincularUsuario.vue'
import PerfilMac from './perfilMac.vue'
export default {
    name:'ContentMac',
    components:{
        perfilMac: ()=> import ('../components/perfilMac'),
        modalVincularUsuario: ()=> import('../../partials/modalVincularUsuario')
    },
    data(){
        return{
            //DATA QUEMADA DE PRUEBA
            user:
                {
                    id: 17,
                    nombre: 'Marcos Aurelio Gutierrez',
                    cargo: 'Administrador',
                    nacimiento:'11-03-1994',
                    activo: true,
                    image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
                    admin: true
                },
            userSeleccionado : null
            
        }
    },
    methods:{
        verEditar(){
            alert('editar')
        },
        actionVincular(){
            this.$refs.modalVincular.toggle()
        },
        VincularUsuario(user){
            this.userSeleccionado = user
        },
        desvincularUsuario(user){   
            this.userSeleccionado = null
        }
    }
}
</script>   

<style lang="scss" scoped>
    .content-mac{
        &__vincular{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>