<template>
    <section v-loading="loading.global">
        <ValidationObserver ref="form" v-slot="{invalid}">
            <header-page title="Crear/Editar Contacto" ruta-atras="configurar.usuarios.e-learning" custom-back @back="atras">
                <div class="col-auto ml-auto">
                    <button :disabled="invalid" class="btn bg-general text-white py-2 shadow px-5 br-12" @click="onSubmit()">
                        Guardar
                    </button>
                </div>
            </header-page>
            <div class="row mx-0 py-2">
                <div class="col border-right">
                    <div class="row mx-0">
                        <!-- Slim image user -->
                        <div class="col custom-scroll overflow-auto " style="height:calc(100vh - 220px);">
                            <div class="row text-general f-600 f-18 j-center mb-4">
                                Datos de Contacto
                            </div>
                            <div class="row mx-0 j-center py-2">
                                <slim-cropper ref="cropperFoto" :options="slimOptions" class="border-gris slim-styles cr-pointer" style="" />
                            </div>
                            <div class="row mx-0 f-18 text-gris a-center j-center">
                                Administrador
                                <el-checkbox v-model="rol" class="pl-3" />
                            </div>
                            <div class="row mx-0 a-center j-center mt-1">
                                <div class="col-auto text-general f-600 f-18">
                                    Gestionar Usuarios 
                                </div>
                                <el-checkbox v-model="form.permiso_usuarios" />
                            </div>
                            <div class="row mx-0 j-center my-3">
                                <div class="col-10">
                                    <ValidationProvider v-slot="{errors}" rules="required" vid="nombre" name="nombre">
                                        <label class="pl-3 text-gris f-15 my-2"> Nombre </label>
                                        <el-input v-model="form.nombre" class="w-100 input-name input-pr" show-word-limit maxlength="40" />
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row mx-0 j-center my-3">
                                <div class="col-10">
                                    <label class="pl-3 text-gris f-15 my-2"> Fecha de Nacimiento </label>
                                    <el-date-picker v-model="form.nacimiento" format="dd / MMM / yyyy" value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptions" />
                                </div>
                            </div>
                            <div class="row mx-0 j-center">
                                <div class="col-10 my-2">
                                    <label class="pl-3 text-gris f-15 my-2"> Cargo </label>
                                    <el-input v-model="form.cargo" class="w-100 input-name input-pr" show-word-limit maxlength="35" />
                                </div>
                                <div class="col-10 my-2">
                                    <ValidationProvider v-slot="{errors}" rules="required|email" vid="correo" name="correo">
                                        <label class="pl-3 text-gris f-15 my-2"> Correo </label>
                                        <el-input v-model="form.correo" class="w-100 input-name input-pr" autocomplete="new-password" show-word-limit maxlength="100" />
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                                <div v-if="!_.isEmpty(currentUser)" class="col-10 d-middle my-3">
                                    <div class="bg-white text-gris shadow py-2 px-4 br-12 cr-pointer" @click="form.show_password = !form.show_password">
                                        Cambiar Contraseña
                                    </div>
                                </div>
                                <template v-if="form.show_password || _.isEmpty(currentUser)">
                                    <div class="col-10 my-2">
                                        <ValidationProvider v-slot="{errors}" rules="required" vid="password" name="contraseña">
                                            <label class="pl-3 text-gris f-15 my-2"> Contraseña </label>
                                            <el-input v-model="form.password" class="w-100 input-name" autocomplete="new-password" show-password show-word-limit maxlength="200" />
                                            <p class="text-danger f-10">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-10 my-2">
                                        <ValidationProvider v-slot="{errors}" rules="required|confirmed:password" vid="password_confirmation" name="confirmar contraseña">
                                            <label class="pl-3 text-gris f-15 my-2"> Repetir Contraseña </label>
                                            <el-input v-model="form.password_confirmation" class="w-100 input-name" show-password show-word-limit maxlength="200" />
                                            <p class="text-danger f-10">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                </template>
                            </div>
                            <div class="row mx-0 j-center">
                                <div class="col-10 my-3">
                                    <label class="text-gris pl-3 f-15"> Fecha de Vinculación </label>
                                    <el-date-picker v-model="form.vinculacion" format="dd / MMM / yyyy" value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptions" />
                                </div>
                            </div>
                            <div class="row mx-0 j-center">
                                <div class="col-10 my-3">
                                    <label class="text-gris pl-3 f-15"> Agregar etiquetas </label>
                                    <el-select
                                    v-model="etiquetaSelected"
                                    filterable
                                    :remote-method="getEtiquetas"
                                    :loading="loading.etiquetas"
                                    class="w-100 my-2"
                                    remote
                                    @change="saveEtiqueta"
                                    >
                                        <el-option
                                        v-for="(item, i) in etiquetasFiltered"
                                        :key="i"
                                        :label="item.etiqueta"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <div class=" br-12 py-1 mt-2">
                                        <div v-for="(etiqueta , i) in form.etiquetas" :key="i" class="row mx-0">
                                            <div class="col-auto px-0 pl-3">
                                                {{ etiqueta.etiqueta }}
                                            </div>
                                            <i class="icon-cancel f-18 text-general pr-2 cr-pointer ml-auto" @click="removeEtiqueta(etiqueta)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-loading="loading.responsable" class="col border-left pt-2 custom-scroll overflow-auto" style="height:calc(100vh - 220px);">
                    <template v-if="!_.isEmpty(currentUser)">
                        <div class="row mx-0 text-gris f-600 f-18">
                            <div class="col-10 ml-3 py-2">
                                Responsable de Cursos
                            </div>
                        </div>
                        <div class="row mx-0 j-center">
                            <div class="col-10 mt-3">
                                <el-select
                                v-model="cursoResponsableSelected"
                                filterable
                                multiple
                                class="w-100 my-2"
                                >
                                    <el-option
                                    v-for="(item, i) in cursosResponsableFiltered"
                                    :key="i"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                            </div>
                        </div>
                        <div class="row mx-0 j-center my-2">
                            <button class="btn bg-general text-white px-5 br-12 py-2 j-center" @click="addCursoResponsable()">
                                Agregar
                            </button>
                        </div>
                        <div v-if="cursosResponsableSaved.length" class="row mx-0">
                            <div class="col">
                                <div v-for="(curso, i) in cursosResponsableSaved" :key="i" class="bg-whitesmoke border-gris br-12 py-1 mt-2">
                                    <div class="row mx-0">
                                        <div class="col-auto px-0 pl-3">
                                            {{ curso.nombre }}
                                        </div>
                                        <div class="col-auto ml-auto f-15">
                                            <i class="icon-account f-18 text-general" />
                                            ({{ curso.count }})
                                        </div>
                                        <i class="icon-cancel f-18 text-general pr-2 cr-pointer" @click="eliminar(curso, 1)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-loading="loading.lector" class="col border-left custom-scroll pt-2 overflow-auto" style="height:calc(100vh - 220px);">
                    <template v-if="!_.isEmpty(currentUser)">
                        <div class="row mx-0 a-center">
                            <div class="col-auto text-gris f-600 f-18">
                                Acceso a cursos 
                            </div>
                        </div>
                        <!-- Select -->
                        <div class="row mx-0">
                            <div class="col-10 mt-3">
                                <el-select
                                v-model="cursoLectorSelected"
                                filterable
                                multiple
                                class="w-100 my-2"
                                >
                                    <el-option
                                    v-for="(item, i) in cursosLectorFiltered"
                                    :key="i"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                            </div>
                        </div>
                        <div class="row mx-0 j-center my-2">
                            <button class="btn bg-general text-white px-5 br-12 py-2 j-center" @click="addCursoLector()">
                                Agregar
                            </button>
                        </div>
                        <div v-if="cursosLectorSaved.length" class="row mx-0">
                            <div class="col">
                                <div v-for="(curso, i) in cursosLectorSaved" :key="i" class="bg-whitesmoke border-gris br-12 py-1 mt-2">
                                    <div class="row mx-0">
                                        <div class="col-auto px-0 pl-3">
                                            {{ curso.nombre }}
                                        </div>
                                        <div class="col-auto ml-auto f-15">
                                            ({{ curso.avance }})
                                        </div>
                                        <i class="icon-cancel f-18 text-general pr-2 cr-pointer" @click="eliminar(curso, 2)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </ValidationObserver>
        <modal-eliminar ref="eliminarCurso" :eliminar="true" prefijo="usuario" alerta="Se eliminarán todos los datos y no será posible recuperarlos" @eliminar="onDelete()" />


        <modal ref="modalSalir" :titulo="titulo" :btns="btns" no-aceptar no-cancelar @accion="atras2">
            <div class="container">
                <div class="my-3 text-muted f-14 justify-center text-center px-3">
                    Perderá los datos que no han sido guardados
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import etiquetasServices from '~/services/configurar/etiquetas'
import usuariosServices from '~/services/configurar/usuarios'
import moment from 'moment'
export default {
    name:'CrearEditarContacto',
    layout: 'fullscreen',
    data(){
        return{
            form: {
                rol: false,
                nombre: '',
                nacimiento: null,
                cargo: '',
                correo: '',
                password: '',
                password_confirmation: '',
                vinculacion: moment().format('YYYY-MM-DD'),
                etiquetas: [],
                permiso_usuarios: false,
                show_password: false,

            },
            rol: false,
            searchCurso: '',
            etiquetaSelected: '',
            etiquetas: [],
            checked: false,
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            opened: false,
            loading: {
                global: false,
                etiquetas: false,
                lector: false,
                responsable: false,
            },
            pickerOptions:{
                disabledDate(time){
                    return time.getTime() > Date.now();
                }
            },
            cursoLectorSelected: [],
            cursoResponsableSelected: [],
            cursosLectorSaved: [],
            cursosResponsableSaved: [],
            open: {
                responsable: false,
                lector: false,
            },
            cursosLector: [],
            cursosResponsable: [],
            userSaved: {},
            idCursoDelete: null,
            tipo: null,
            btns: [
                {
                    texto: 'Regresar',
                    color: 'bg-general text-white',
                    accion: false
                },
                {
                    texto: 'Salir',
                    color: 'btn-eliminar px-4',
                    accion: true
                }
            ],
            user: {}

        }
    },
    computed: {
        etiquetasFiltered(){
            const idEtiquetas = this.form.etiquetas.map(el => el.id)
            return this.etiquetas.reduce((acc, el) => {
                if (!idEtiquetas.includes(el.id)){
                    acc.push(el)
                }
                return acc
            }, [])
        },
        cursosLectorFiltered(){
            const ids = this.cursosLectorSaved.map(el => el.id)
            return this.cursosLector.filter(el => !ids.includes(el.id))
        },
        cursosResponsableFiltered(){
            const ids = this.cursosResponsableSaved.map(el => el.id)
            return this.cursosResponsable.filter(el => !ids.includes(el.id))
        },
        currentUser(){
            return this.$store.getters['configurar/usuarios/getCurrentUser']
        },
        titulo(){
            const tipo =  !_.isEmpty(this.currentUser) ? 'edición' : 'creación'
            return `Salir de la ${tipo} del usuario`
        }
    },
    async mounted(){
        await this.getEtiquetas()
        await this.getCursos()
        this.$refs.cropperFoto.instanciaCrop.remove()

        if(!_.isEmpty(this.currentUser)){

            const { data } = await usuariosServices.userData(this.currentUser.id)
            this.user = data.data.user
            if (this.user.imagen){
                this.$refs.cropperFoto.instanciaCrop.load(this.user.imagen)
            }
            this.fillForm()
            await this.getCursosLector()
            await this.getCursosResponsable()
            await this.getEtiquetasUsuario()
        }
    },
    methods: {
        eliminar({id_curso_user}, tipo){
            this.tipo = tipo
            this.idCursoDelete = id_curso_user
            this.$refs.eliminarCurso.toggle();
        },
        async getCursos(){
            try {
                const { data } = await usuariosServices.cursos()
                let datos = data.data.map(el => ({...el, selected: false}))
                this.cursosLector = [...datos]
                this.cursosResponsable = [...datos]
            } catch (e){
                this.error_catch(e)
            }
        },
        async getCursosLector(){
            try {
                this.loading.lector = true
                const { data } = await usuariosServices.cursosUser(1, this.currentUser.id)
                this.cursosLectorSaved = data.data
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading.lector = false
            }
        },
        async getCursosResponsable(){
            try {
                const { data } = await usuariosServices.cursosUser(2, this.currentUser.id)
                this.cursosResponsableSaved = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async getEtiquetas(nombre = ''){
            try {
                this.loading.etiquetas = true
                const params = {
                    nombre,
                }
                const { data } = await etiquetasServices.etiquetasNombre(params)
                this.etiquetas = data.data
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading.etiquetas = false
            }
        },
        saveEtiqueta(){
            const find = this.etiquetas.find(el => el.id == this.etiquetaSelected)
            this.form.etiquetas.push(find)
            this.etiquetaSelected = ''
        },
        removeEtiqueta({id}){
            this.form.etiquetas = this.form.etiquetas.filter(el => el.id != id)
        },
        async getEtiquetasUsuario(){
            try {
                const { data } = await etiquetasServices.etiquetaUsuario(this.currentUser.id)
                this.form.etiquetas = data.data
                
            } catch (e){
                this.error_catch(e)
            }
        },
        async saveUser(){
            try {
                this.loading.global = true
                this.form.permiso_usuarios = +(this.form.permiso_usuarios)
                const foto = this.$refs.cropperFoto.instanciaCrop.dataBase64.output.image
                this.form.foto = foto
                this.form.rol = this.rol ? 2 : 1
                let prov = this.form.etiquetas
                this.form.etiquetas = this.form.etiquetas.map(el => el.id).filter(el => el)
                const { data } = await usuariosServices.save(this.form)
                this.form.etiquetas = prov
                if (_.get(data, 'message', '') == 'Exito!'){
                    this.$store.commit('configurar/usuarios/setCurrentUser', data.data.user)
                    // this.userSaved = data.data.user
                    this.notificacion('', 'Usuario creado correctamente', 'success')
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading.global = false
            }

        },
        async addCursoLector(){
            try {
                this.loading.lector = true
                if(!this.cursoLectorSelected.length){
                    return this.notificacion('', 'Debe seleccionar al menos un curso', 'error')
                }
                const obj = {
                    cursos: this.cursoLectorSelected,
                    tipo: 1,
                    user: this.currentUser.id
                }
                const { data } = await usuariosServices.saveCursos(obj)
                if (_.get(data, 'message', '') == 'Exito!'){
                    this.notificacion('', 'Guardado Correctamente', 'success')
                    this.cursoLectorSelected = []
                    await this.getCursosLector()
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading.lector = false
            }
        },
        async addCursoResponsable(){
            try {
                this.loading.lector = true
                if(!this.cursoResponsableSelected.length){
                    return this.notificacion('', 'Debe seleccionar al menos un curso', 'error')
                }
                const obj = {
                    cursos: this.cursoResponsableSelected,
                    tipo: 2,
                    user: this.currentUser.id
                }
                const { data } = await usuariosServices.saveCursos(obj)
                if (_.get(data, 'message', '') == 'Exito!'){
                    this.notificacion('', 'Guardado Correctamente', 'success')
                    this.cursoResponsableSelected = []
                    await this.getCursosResponsable()
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading.lector = false
            }
        },
        async onDelete(){
            try {
                this.loading.global = true
                const { data } = await usuariosServices.deleteCurso(this.idCursoDelete)
                if (_.get(data, 'message', '') == 'Exito!'){
                    this.notificacion('', 'Eliminado Correctamente', 'success')
                    if (this.tipo == 1){
                        await this.getCursosResponsable()
                    } else { 
                        await this.getCursosLector()
                    }
                    this.$refs.eliminarCurso.toggle();
                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading.global = false
            }
        },
        onSubmit(){
            if(_.isEmpty(this.currentUser)){
                this.saveUser()
            } else {
                this.updateUser()
            }
        },
        async updateUser(){
            try {
                this.loading.global = true
                const foto = this.$refs.cropperFoto.instanciaCrop.dataBase64.output.image
                this.form.foto = foto
                this.form.etiquetas = this.form.etiquetas.map(el => el.id).filter(el => el)
                this.form.permiso_usuarios = +(this.form.permiso_usuarios)
                this.form.rol = this.rol ? 2 : 1
                const { data } = await usuariosServices.update(this.currentUser.id, this.form)
                if (_.get(data, 'message', '') == 'Exito!'){
                    this.notificacion('', 'Usuario actualizado correctamente', 'success')
                    this.$router.push({name: 'configurar.usuarios.e-learning',params: {
                        id_user : this.currentUser.id
                    }})
                    this.limpiar()
                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading.global = false 

            }
        },
        atras(){
            this.$refs.modalSalir.toggle();
        },
        atras2(salir){
            this.$refs.modalSalir.toggle();
            if (salir){
                this.$router.push({name: 'configurar.usuarios.e-learning'})
            }
        },
        fillForm(){
            let currentUser = {...this.user}
            this.rol = currentUser.rol == 2
            this.form.rol = currentUser.rol
            this.form.nombre = currentUser.nombre
            this.form.nacimiento = currentUser.nacimiento ? moment(currentUser.nacimiento).format('YYYY-MM-DD') : null
            this.form.cargo = currentUser.cargo
            this.form.correo = currentUser.correo
            this.form.password = ''
            this.form.password_confirmation = ''
            this.form.vinculacion = moment(currentUser.vinculacion).format('YYYY-MM-DD')
            this.form.permiso_usuarios = !!(currentUser.permiso_usuarios)
        },
        limpiar(){
            this.form =  {
                rol: false,
                nombre: '',
                nacimiento: null,
                cargo: '',
                correo: '',
                password: '',
                password_confirmation: '',
                vinculacion: moment().format('YYYY-MM-DD'),
                etiquetas: [],
                permiso_usuarios: false,
                show_password: false,

            }
        }
    },
}
</script>

<style lang="scss" scoped>
.slim-styles{
    height:216px;
    background:#F8F9FF;
    width:216px;
    border-radius:50%;
}
</style>