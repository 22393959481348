<template>
    <div class="perfil-usuario col-6 p-3">
        <div class="row align-items-center justify-content-end w-100">
            <button 
            v-show="desvincular" 
            class="bg-red px-4 py-2 border-0 br-12 mr-3 shadow cr-pointer text-white"
            style="outline:0"
            @click="$refs.modalDesvincularUsuario.toggle()"
            >
                Desvincular
            </button>

            <button 
            v-show="editar" 
            class="bg-blue px-4 py-2 border-0 br-12 shadow cr-pointer text-white"
            style="outline:0"
            @click="actionEditar"
            >
                Editar
            </button>
        </div>

        <div class="col-10 p-0 mx-auto text-center" style="margin-top: 80px">
            <div class="col-10 mx-auto p-0">
                <figure class="mx-auto rounded-circle" style="width:147px; height:147px;">
                    <img :src="user.image" width="145" height="145" alt="imagen-usuario" class="obj-cover rounded-circle" :style="user.activo ? 'border: 6px solid rgba(0, 210, 70,.3)': ''" />
                </figure>

                <p v-if="user.activo" class="f-600">Activo</p>
                <p v-else class="f-600">Inactivo</p>
            </div>
            <div class="col-10 p-0 mx-auto mt-5 ">
                <p class="f-600 f-20">{{ user.nombre }}</p>
                <p class="f-18">{{ user.cargo }}</p>
            </div>
        </div>
        <div>
            <div class="col" style="margin: 100px 0px 50px">
                <div class="row justify-content-end px-3">
                    <router-link
                    :to="{
                        name:'configurar.usuarios.e-learning',

                        params:{id_user:user.id}
                    }"
                    class="m-0 p-0"
                    style="color: var(--color-5d)"
                    >
                        <p v-show="desvincular" class="f-18 f-600">Ir al perfil <i class="icon-user-circle f-18" style="color: #00125A" /></p>
                    </router-link>
                </div>
            </div>
        </div>
        <modal-desvincular-usuario ref="modalDesvincularUsuario" titulo="¿Está seguro que desea deshacer el vinculo?" noCancelar eliminar @eliminar="actionDesvincular(user)" />
        <modal-editar-usuarios ref="editarUsuarios" :userPerfil="desvincular ?'elearning': 'mac'" />
    </div>
</template>

<script>
import ModalEditarUsuario from '../../partials/modalEditarUsuario.vue'
export default {
    name:'PerfilMac',
    components:{
        modalDesvincularUsuario: () => import('../../../../components/modal/eliminar'),
        modalEditarUsuarios: ()=> import('../../partials/modalEditarUsuario')
    },
    props:{
        user:{
            type: Object,
            default: () => {}
        },
        editar:{
            type: Boolean,
            default: true
        },
        desvincular:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        actionEditar(){
            this.$refs.editarUsuarios.toggle()
        },
        actionDesvincular(user){
            this.$emit('desvincularUsuario',user)
            this.$refs.modalDesvincularUsuario.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
    .perfil-usuario{
        border-right: 1px solid #dee2e6;
    }
</style>