<template>
    <section v-loading="loading">
        <ValidationObserver ref="form">
            <header-page title="Configurar Sistema" ruta-atras="configurar">
                <div class="col-auto ml-auto">
                    <button class="btn bg-general text-white ml-auto py-2 px-5 br-12 shadow-button" @click="onSubmit()">
                        Guardar
                    </button>
                </div>
            </header-page>
            <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 220px);">
                <div class="row mx-0">
                    <div class="col-auto px-5" />
                    <div class="col">
                        <!-- Logos y modo Oscuro -->
                        <div class="row mx-0">
                            <!-- Crop Modo Claro -->
                            <div v-show="!form.login_tema">
                                <div class="col py-4 border-right">
                                    <div class="row mx-0">
                                        <div class="col-auto">
                                            <p class="text-gris pl-4 f-18 f-600 mb-3">
                                                Logo Color
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mx-0 j-center">
                                        <slim-cropper ref="cropperLogo" :options="slimOptionsLogo" class="border slim-styles cr-pointer" style="" />
                                    </div>
                                </div>
                            </div>
                            <!-- Crop Modo oscuro -->
                            <div v-show="form.login_tema">
                                <div class="col py-4 border-right">
                                    <div class="row mx-0">
                                        <div class="col-auto">
                                            <p class="text-gris pl-4 f-18 f-600 mb-3">
                                                Logo (Modo Oscuro)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mx-0 j-center">
                                        <slim-cropper ref="cropperLogoOscuro" :options="slimOptionsLogoOscuro" class="border slim-styles cr-pointer" style="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col py-4">
                                <p class="text-gris pl-4 f-18 f-600 mb-3">
                                    Fondo Login
                                </p>
                                <div class="row mx-0 j-center">
                                    <slim-cropper ref="cropperFondo" :options="slimOptionsBackground" class="border slim-styles_background cr-pointer" style="" />
                                </div>
                                <p class="text-muted text-center mt-2">
                                    1920 x 1080
                                </p>
                            </div>
                            <div class="col-lg col-md-12 py-4">
                                <p class="text-gris f-18 f-600 mb-3 pl-3">
                                    Color Login
                                </p>
                                <p class="mt-3">
                                    Si el fondo de pantalla que va a utilizar en el login es oscuro active esta opción para que los elementos como el mensaje de bienvenida sean más visibles.
                                </p>
                                <div class="row mx-0 mt-3 ">
                                    <div class="col-auto px-0 d-flex a-center">
                                        Modo Oscuro
                                        <el-switch
                                        v-model="form.login_tema"
                                        class="mini mx-2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Colores y Campos -->
                        <div class="row mx-0">
                            <div class="col">
                                <p class="f-600 text-gris pl-5 mb-3">
                                    Color del Sistema
                                </p>
                                <div class="custom-grid pt-3 mx-0">
                                    <div 
                                    v-for="(color,c) in colores" 
                                    :key="c" 
                                    class="d-flex j-center px-0"
                                    >
                                        <div 
                                        class="rounded-circle mx-2 my-1 shadow-button cr-pointer" 
                                        :class="form.color == color.color ? 'color-active p-1' : ''"
                                        style="width:35px;height:35px;"
                                        @click="setColor(color.color)" 
                                        >
                                            <div class="w-100 h-100 rounded-circle" :style="`background-color: ${color.color};`" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-0 mt-3">
                                    <div class="col-auto">
                                        <p class="f-600 text-gris pl-5 pl-md-4 mb-3">
                                            Mensajes de Bienvenida  
                                        </p>
                                    </div>
                                    <div class="col-10 my-2">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:150" vid="bienvenida_titulo" name="título">
                                            <label class="pl-3 text-gris"> Título </label>
                                            <el-input v-model="form.bienvenida_titulo" size="medium" class="w-100" show-word-limit maxlength="150" />
                                            <p class="text-danger f-10">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-10 my-2">
                                        <ValidationProvider v-slot="{errors}" rules="required|max:1000" vid="bienvenida_texto" name="texto">
                                            <label class="pl-3 text-gris"> Texto </label>
                                            <el-input v-model="form.bienvenida_texto" size="medium" type="textarea" :rows="3" class="w-100" show-word-limit maxlength="1000" />
                                            <p class="text-danger f-10">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <p class="pl-1 mb-3 f-600 f-18">
                                    Redes Sociales
                                </p>
                                <div v-for="(red, r) in redes_sociales" :key="r" class="row mx-0 my-3">
                                    <div class="col-auto d-middle">
                                        <i :class="`icon-${red.icon} ${red.link != '' ? 'text-general' :'text-gris'} f-20`" />
                                    </div>
                                    <div class="col-10">
                                        <ValidationProvider v-slot="{errors}" :rules="{regex: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/}" :vid="`link-${r}`" name="nombre">
                                            <el-input v-model="red.link" class="w-100 input-name" :placeholder="red.holder" />
                                            <!-- <span class="error" v-if="errors.has('email'+i)">{{errors.first('email'+i)}}  -->
                                            <p v-if="errors[0]" class="text-danger f-10">Formato del link es invalido, debe contener <i>http://</i> ó <i>https://</i></p>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import sistema from '~/services/configurar/sistema';
export default {
    layout: 'fullscreen',
    data(){
        return {
            slimOptionsLogo: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            slimOptionsLogoOscuro: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            slimOptionsBackground: {
                ratio: '16:9',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            value2: false,
            colores: [
                { color: '#00125A', id: 1 },
                { color: '#14B0BF', id: 2 },
                { color: '#FF3B3B', id: 3 },
                { color: '#00D246', id: 4 },
                { color: '#FFA200', id: 5 },
                { color: '#000000', id: 6 },
                { color: '#FF5700', id: 7 },
                { color: '#2914BF', id: 8 },
                { color: '#D20007', id: 9},
                { color: '#659A94', id: 10},
                { color: '#16802F', id: 11},
                { color: '#9DE1F9', id: 12}
            ],
            redes_sociales: [
                { red: 1, icon: 'globe', link: '', holder: 'www.globe.com/ejemplo' },
                { red: 2, icon: 'facebook', link: '', holder: 'www.facebook.com/ejemplo' },
                { red: 3, icon: 'instagram', link: '', holder: 'www.instagram.com/ejemplo' },
                { red: 4, icon: 'twitter', link: '', holder: 'www.twitter.com/ejemplo' },
                { red: 5, icon: 'linkedin-squared', link: '', holder: 'www.linkedin.com/ejemplo' },
                { red: 6, icon: 'youtube-play', link: '', holder: 'www.youtube.com/ejemplo' }
            ],
            titulo: '',
            texto: '',
            form: {
                login_tema: false,
                bienvenida_titulo: '',
                bienvenida_texto: '',
                color: '#00125A'
            },
            loading: false,
        }
    },
    computed: {
        colorActual(){
            return this.$store.getters['auth/colorSistema']
        }
    },
    mounted(){
        this.find();
    },
    methods: {
        setColor(color){
            this.form.color = color
        },
        setLogo(ref, param){
            if (param){
                this.$refs[ref].instanciaCrop.load(param)
            } else {
                this.$refs[ref].instanciaCrop.remove()
            }
        },
        async find(){
            try {
                this.loading = true

                const { data } = await sistema.find();
                console.log(data);
                if (data.data.parametros){
                    this.form.login_tema = !!(data.data.parametros.login_tema)
                    this.form.bienvenida_titulo = data.data.parametros.bienvenida_titulo
                    this.form.bienvenida_texto = data.data.parametros.bienvenida_texto
                    this.form.color = data.data.parametros.color
                    this.setLogo('cropperLogo', data.data.parametros.logo_firmado)
                    this.setLogo('cropperFondo', data.data.parametros.fondo_firmado)
                    this.setLogo('cropperLogoOscuro', data.data.parametros.logo_oscuro_firmado)
                }
                if(data.data.redes.length){
                    data.data.redes.forEach(el => {
                        const index = this.redes_sociales.findIndex(elm => elm.red == el.red)
                        this.redes_sociales[index].link = el.link
                    })
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async onSubmit(){
            try {
                const valid = await this.$refs.form.validate()
                if (valid){
                    this.loading = true
                    const logo = this.$refs.cropperLogo.instanciaCrop.dataBase64.output.image
                    const logoOscuro = this.$refs.cropperLogoOscuro.instanciaCrop.dataBase64.output.image
                    const fondo = this.$refs.cropperFondo.instanciaCrop.dataBase64.output.image
                    this.form.logo = logo
                    this.form.logo_oscuro = logoOscuro
                    this.form.fondo = fondo
                    this.form.redes = this.redes_sociales.reduce((acc, el) => {
                        if(!_.isEmpty(el.link)){
                            acc.push({red: el.red, link: el.link,})
                        }
                        return acc
                    }, [])
                    const { data } = await sistema.save(this.form)
                    if (data){
                        this.notificacion('','Guardado correctamente', 'success')
                        this.$store.commit('auth/setColorSistema', this.form.color)
                        this.$store.commit('auth/cambiarColorSistema')
                    }
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.custom-grid{
    display: grid;
    grid-template-columns: 80px 80px 80px 80px 80px 80px;
}
.slim-styles{
    height:216px;
    background:#F8F9FF;
    width:216px;
    border-radius:12px;
}
.slim-styles_background{
    height: 215px;
    background:#F8F9FF;
    width: 383px;
    border-radius:12px;
}
.shadow-button{ box-shadow: 0px 3px 6px #0000001F }
.color-active{
    border-color: #FFF;
    border-width: 1px;
}
@media (max-width: 1250px){
    .custom-grid{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
    }
}
</style>