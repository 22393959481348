<template>
    <div class="row mx-0 h-100">
        <div class="col pr-0">
            <div class="row mx-0 h-100">
                <div class="col-12 d-middle j-center">
                    <img :src="`/img/${ parametros.login_tema == 0 ? 'e-learning_oscuro.svg' : 'e_learning.svg'}`" style="max-width:100%;height:150px;" class="logo" />
                </div>
                <div class="col-12 text-inverse main-title d-xl-flex align-items-xl-center justify-content-xl-center">
                    {{ parametros.bienvenida_titulo }}
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <p class="subtitle descripcion custom-scroll  text-inverse">
                        {{ parametros.bienvenida_texto }}
                    </p>
                </div>
                <div class="col-12 px-0">
                    <div class="row mx-0 px-0 col-images h-100 align-items-end">
                        <div class="col-auto mb-2 d-flex align-items-end">
                            <img :src="`/img/${parametros.login_tema == 0 ? 'megaarchivo_dark.png' : 'megaarchivo.svg'}`" style="max-width:100%;height:70px;" />
                        </div>
                        <div class="col-auto px-2 mega-text f-25 d-flex a-center mt-3 text-inverse">
                            www.megaarchivo.com
                        </div>
                        <div class="col-auto px-1 d-flex a-center">
                            <a v-for="(red,r) in redes" :key="r" class="f-28 text-inverse" :class="`icon-${red.icono}`" :href="red.link" target="_blank" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto d-flex j-center a-center">
            <div class="box-login px-2 pt-xl-5 pt-lg-2 d-flex flex-column justify-content-start" style="border-radius:36px; box-">
                <ValidationObserver ref="form" v-slot="{invalid}">
                    <div class="row mx-0 j-center mt-2">
                        <div class="col-auto px-0">
                            <img :src="parametros.login_tema == 1 ? parametros.logo_oscuro_firmado : parametros.logo_firmado" style="max-width:100%;height:159px;" class="image-essi" />
                        </div>
                    </div>
                    <div class="row mx-0 j-center">
                        <div class="col-10 my-4">
                            <ValidationProvider v-slot="{errors}" rules="required" name="correo">
                                <el-input 
                                v-model="payload.correo"
                                class="w-100 input-name"
                                :disabled="cargando"
                                placeholder="Correo" 
                                />
                                <p class="text-danger f-14 pl-2" v-text="errors[0]" />
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-4">
                            <ValidationProvider v-slot="{errors}" rules="required" name="contraseña">
                                <el-input
                                v-model="payload.password"
                                class="w-100 input-name"
                                :disabled="cargando"
                                placeholder="Contraseña"
                                show-password 
                                @keyup.enter.native="iniciarSesion"
                                />
                                <p class="text-danger f-14 pl-2" v-text="errors[0]" />
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 j-center">
                        <div :class="parametros.login_tema == 0  ? 'text-white' : ' '" class="col-10  f-17 text-right ">
                            <span class="cr-pointer text-forget" @click="abrirModalRecuperarPassword">
                                Olvidé mi contraseña
                            </span>
                        </div>
                    </div>
                    <div class="row mx-0 j-center my-4">
                        <div class="col-auto">
                            <el-button
                            v-loading="cargando"
                            :disabled="invalid"
                            class="btn f-17 bg-white br-5 btn-block shadow text-center"
                            style="width:304px;height:44px;"
                            @click="iniciarSesion"
                            >
                                Iniciar sesión
                            </el-button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
        <div class="col-auto" />
        <modalRecuperarPassword ref="modalRecuperarPassword" />
    </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'
const VUEX_RUTA = 'auth'
export default {
    components:{
        modalRecuperarPassword: ()=> import('~/pages/auth/partials/modalRecuperarPassword')
    },
    layout: 'login',
    data(){
        return {
            payload: {
                correo: '',
                password: ''
            },
            cargando: false,
            erroresSesion: ['U001', 'C001'],
        }
    },
    computed: {
        ...mapGetters({
            error: `${VUEX_RUTA}/obtenerError`,
            parametros: `${VUEX_RUTA}/parametros`,
            redes: `${VUEX_RUTA}/redes`,
        }),
        existenErrores(){
            return !_.isEmpty(this.error)
        },
        existeErrorSesion(){
            return this.existenErrores && this.erroresSesion.includes(this.error.codigo)
        },
        existeErrorServidor(){
            return this.existenErrores && this.error.tipo === 500
        }
    },
    watch: {
        parametros(newValue){
            console.log(newValue);
            this.setThemeLogin();
        }
    },
    methods:{
        ...mapActions({
            intentarIniciarSesion: `${VUEX_RUTA}/intentarIniciarSesion`,
        }),
        ...mapMutations({
            almacenarError: `${VUEX_RUTA}/ALMACENAR_ERROR`,
        }),
        alternarCargando(){
            this.cargando = !this.cargando
        },
        iniciarSesion(){
            this.alternarCargando()
            this.intentarIniciarSesion(this.payload)
                .catch(this.almacenarError)
                .finally(() => {
                    this.alternarCargando();
                    this.intentarIrAVerificacion();
                });
        },
        intentarIrAVerificacion(){
            if (this.existeErrorServidor || this.existeErrorSesion) return

            this.$router.push({ name: 'home' })
        },
        abrirModalRecuperarPassword(){
            this.$refs.modalRecuperarPassword.toggle()
        },
        setThemeLogin(){
            /* Get Html elements */
            let box = document.querySelector('.box-login');
            let app = document.querySelector('#app');
            if(this.parametros.login_tema == 0){
                
                /* Set Dark Theme */
                app.style.setProperty('--color-login', '#2f2f2ff5')
                box.style.backgroundColor = '#2f2f2ff5'
            }else if(this.parametros.login_tema == 1){
                app.style.setProperty('--color-login', '#FFFFFF')
                box.style.backgroundColor = '#FFFFFF'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
/*  Color Logo Oscuro */
.box-login{
    box-shadow: 0px 3px 6px #00000029;
    width: 530px;
    height: calc(100vh - 410px);
    background-color: #FFF;
    border-radius: 36px !important;
}
.descripcion{
    min-height: 80px;
    max-height: 260px;
    overflow-y: auto;
    overflow-x: hidden;
    display: -webkit-box;
    white-space: pre-line;
}
.main-title{
    /* color: #F8F8F8 !important; */
    font-size: 48px !important;
    text-align: center;
}
.subtitle{
    /* color: #F8F8F8 !important; */
    font-size: 28px !important;
    text-align: center;
}
.text-forget:hover{
    color: var(--color-general);
}
/* Media Querys Width */
@media (max-width: 1366px){
    .box-login{
        width: 550px;
        height: calc(100vh - 300px) !important;
    }
    .main-title{
        font-size: 30px !important;
    }
    .subtitle{
        font-size: 20px !important;
    }
}
@media (max-width: 1250px){
    .box-login{
        width: 430px;
        height: calc(100vh - 300px) !important;
    }
    .main-title{
        font-size: 30px !important;
    }
    .subtitle{
        font-size: 20px !important;
    }
    .mega-text{
        font-size: 19px !important;
    }
}
@media (max-width: 830px){
    .box-login{
        width: 430px !important;
    }
    .col-images{
        display: none;
    }
}
/* Media Querys Width */
@media (max-height: 900px){
    .box-login{
        height: auto !important;
    }
}
@media (max-height: 780px){
    .box-login{
        height: calc(100vh - 180px) !important;
    }
}
@media (max-height: 680px){
    .box-login{
        height: calc(100vh - 95px) !important;
    }
}
@media (max-height: 519px){
    .box-login{
        height: auto !important;
    }
}
@media (min-height: 1024px) {
    .box-login{
        width: 550px;
        height: calc(100vh - 430px) !important;
    }
}
</style>