import axios from 'axios';

const API_PREFIX = 'mensajes';

const ENDPOINTS = {
    listarUsuariosMensajes: (payload) => axios.get(`${API_PREFIX}/cursos/${payload.id_curso}/usuarios`),
    listarMensajesUsuario: (payload) => axios.get(`${API_PREFIX}/cursos/${payload.id_curso}/usuarios/${payload.id_user}`, {params: payload}),
    guardarMensaje: (payload) => axios.post(`${API_PREFIX}`, payload)

};

export default ENDPOINTS;
