<template>
    <modal ref="editarUsuario" titulo="Editar datos" @guardar="editarUsuario">
        <div v-if="userPerfil === 'elearning'" class="row mx-0">
            <div class="col custom-scroll overflow-auto " style="height:calc(100vh - 220px);">
                <div class="row mx-0 j-center py-2">
                    <slim-cropper ref="cropperFoto" :options="slimOptions" class="border-gris slim-styles cr-pointer" style="" />
                </div>
                <div class="row mx-0 f-18 text-gris a-center j-center">
                    Administrador
                    <el-switch
                    v-model="form.rol"
                    class="mini mx-2"
                    />
                </div>
                <div class="row mx-0 j-center my-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" rules="required" vid="nombre" name="nombre">
                            <label class="pl-3 text-gris f-15 my-2"> Nombre </label>
                            <el-input v-model="form.nombre" class="w-100 input-name input-pr" show-word-limit maxlength="40" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 j-center my-3">
                    <div class="col-10">
                        <label class="pl-3 text-gris f-15 my-2"> Fecha de Nacimiento </label>
                        <el-date-picker v-model="form.nacimiento" format="dd / MMM / yyyy" value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptions" />
                    </div>
                </div>
                <div class="row mx-0 j-center">
                    <div class="col-10 my-2">
                        <label class="pl-3 text-gris f-15 my-2"> Cargo </label>
                        <el-input v-model="form.cargo" class="w-100 input-name input-pr" show-word-limit maxlength="35" />
                    </div>
                </div>
                <div class="row mx-0 j-center">
                    <div class="col-10 my-3">
                        <label class="text-gris pl-3 f-15"> Fecha de Vinculación </label>
                        <el-date-picker v-model="form.vinculacion" format="dd / MMM / yyyy" value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptions" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="userPerfil === 'mac'" class="row mx-0">
            <div class="col custom-scroll overflow-auto " style="height:calc(100vh - 220px);">
                <div class="row mx-0 j-center py-2">
                    <slim-cropper ref="cropperFoto" :options="slimOptions" class="border-gris slim-styles cr-pointer" style="" />
                </div>  
                <div class="row mx-0 j-center my-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" rules="required" vid="nombre" name="nombre">
                            <label class="pl-3 text-gris f-15 my-2"> Nombre </label>
                            <el-input v-model="form.nombre" class="w-100 input-name input-pr" show-word-limit maxlength="40" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from 'moment'
export default {
    props:{
        userPerfil:{
            type: String,
            default:() => null
        }
    },
    data(){
        return{
            form: {
                rol: false,
                nombre: '',
                nacimiento: moment().format('YYYY-MM-DD'),
                cargo: '',
                correo: '',
                password: '',
                password_confirmation: '',
                vinculacion: moment().format('YYYY-MM-DD'),
                etiquetas: [],
                permiso_usuarios: false,
                show_password: false,

            },
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            pickerOptions:{
                disabledDate(time){
                    return time.getTime() > Date.now();
                }
            },
        }
    },
    methods:{
        toggle(){
            this.$refs.editarUsuario.toggle()
        },
        editarUsuario(){
            alert('usuario editado')
            this.$refs.editarUsuario.toggle()

        }
    }
}
</script>
<style lang="scss" scoped>
.slim-styles{
    height:216px;
    background:#F8F9FF;
    width:216px;
    border-radius:50%;
}
</style>