<template>
    <section class="mis-cursos custom-scroll overflow-auto" style="height:calc(100vh - 160px);">
        <header-page title="Configurar Cursos" ruta-atras="configurar">
            <div class="col-auto ml-auto">
                <div class="bg-general text-white ml-auto cr-pointer py-2 px-5 br-12 shadow-button" @click="crearCarpeta">
                    Crear Carpeta
                </div>
            </div>
        </header-page>

        <div class="row mx-0 j-center pb-4 pt-4">
            <div v-for="(carpeta,c) in carpetas" :key="c" class="col-auto my-3">
                <div class="border br-12 card-curso p-2 cr-pointer" @click="irRuta(carpeta)">
                    <img :src="carpeta.imagen_firmada" height="223" width="100%" class="obj-cover br-t-12" />
                    <div class="row mx-0 mt-3">
                        <div class="col-12 px-0 text-gris f-600 f-22 descripcion">
                            {{ carpeta.nombre }}
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 a-center">
                        <div class="col-auto pr-0 f-16 text-gris">
                            <span class="br-12 f-600 f-17">Cursos</span>
                            {{ carpeta.cursos_count }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="carpetas.length <=0" class="row">
                No se han creado carpetas
            </div>
        </div>
    </section>
</template>

<script>
import CarpetasCursos from '~/services/cursos/cursos_lector'

import {mapGetters} from 'vuex'

export default {
    layout: 'fullscreen',
    middleware:['auth'],
    data(){
        return{
            carpetas: []
        }
    },

    mounted(){
        this.listarCarpetas() 
    },
    methods: {
        async listarCarpetas(){
            try {
                const {data: {data}} = await CarpetasCursos.obtenerTodasCarpetas()
                this.carpetas = data.carpetas
            } catch (e){
                this.error_catch(e)
            }
        },
        crearCarpeta(){
            this.$router.push({name: 'mis-cursos.editar.carpeta'})
        },
        irRuta(carpeta){
            if(this.$usuario.rol===1){// lector
                this.$router.push( { name: 'mis-cursos.editar.carpeta', params:{id_carpeta: carpeta.id}} )
            }else{// admin va a vist asimilar a responable
                this.$router.push( { name: 'configurar.carpeta.ver', params:{id_carpeta: carpeta.id}} )
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.card-curso{
    min-height:339px;
    width: 397px;
    border: 1px solid #DFDFDF;
}
.header-drop{
    background: #F8F8F8;
    border: 1px solid #DFDFDF;
    border-radius: 12px;
}
.descripcion{
    height: 56px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>