<template>
    <div v-loading="loading" class="col pt-3">
        <div class="row justify-md-center mx-0">
            <div class="col-auto px-0 ml-auto">
                <div class="bg-red px-4 py-2 br-12 shadow cr-pointer text-white" @click="eliminar()">
                    Eliminar 
                </div>
            </div>
            <div class="col-auto mx-1">
                <button class="bg-blue px-4 py-2 br-12 shadow cr-pointer text-white border-0" @click="editarUsuario">
                    Editar 
                </button>
            </div>
        </div>
        <div class="row j-center mx-0 pt-2 border-bottom pb-3">
            <div class="col-auto px-0">
                <img :src="user.imagen" class="obj-cover rounded-circle border-gris" width="147" height="147" />
            </div>
            <div class="col px-0 pt-3">
                <div class="row mx-0">
                    <div class="col">
                        <div class="row mx-0 a-center">
                            <p class="col f-600 f-18 tres-puntos pl-0 ">
                                {{ user.nombre }}
                            </p>
                            <div v-if="user.rol == 2" class="col-auto px-0">
                                <div class="bg-white border-edit br-12 py-1 px-3">
                                    Admin
                                </div>
                            </div>
                        </div>
                        <p class="text-gris f-17 my-2">
                            {{ user.cargo }}
                        </p>
                        <p class="text-gris f-17 my-2">
                            {{ formatYears(user.nacimiento) }} Años
                        </p>
                    </div>
                    <div class="col text-lg-left mb-2 text-xl-right">
                        <p class="text-gris f-17 my-2">
                            Fecha de vinculacion: {{ formatDate(user.vinculacion) }}
                        </p>
                        <p class="text-gris f-17">  
                            {{ user.correo }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 px-2">
                    <div v-for="(etiqueta, i) in etiquetas" :key="i" class="bg-white border-edit f-15 py-1 px-2 br-12 mx-2 my-1">
                        {{ etiqueta.etiqueta }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-2 custom-scroll overflow-auto" style="height:calc(100vh - 408px)">
            <div class="col border-right">
                <template v-if="user.permiso_usuarios">
                    <p class="f-600 my-4 text-gris">
                        Permisos para 
                    </p>
                    <p class="f-600 my-4 text-general">
                        Administrar Usuarios
                    </p>
                </template>
                <p class="f-600 my-4 text-gris">
                    Responsable de curso
                </p>
                <div v-for="(curso, i) in responsable" :key="i" class="row mx-0">
                    <div class="col bg-whitesmoke br-12 border py-1 my-2">
                        <div class="row mx-0 a-center">
                            <p class="col pl-0 tres-puntos">
                                {{ curso.nombre }}
                            </p>
                            <div class="col-auto px-0">
                                <i class="icon-account f-18 text-general" />
                                <el-tooltip effect="light" content="Responsables del curso" placement="bottom">
                                    ({{ curso.count }})
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <p class="f-600 my-4 text-gris">
                    Cursos
                </p>
                <div v-for="(curso, i) in lector" :key="i" class="row mx-0">
                    <div class="col bg-whitesmoke br-12 border py-1 my-2">
                        <div class="row mx-0 a-center">
                            <p class="col pl-0 tres-puntos">
                                {{ curso.nombre }}
                            </p>
                            <div class="col-auto px-0">
                                <i class="icon-account f-18 text-general" />
                                ({{ curso.avance }}) %
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-eliminar ref="eliminarUsuario" :eliminar="true" prefijo="usuario" alerta="Se eliminarán todos los datos y no será posible recuperarlos" @eliminar="deleteUsuario(user.id)" />
    </div>
</template>

<script>
import moment from 'moment'
import usuarios from '~/services/configurar/usuarios'

export default {
    name:'ContentElearning',
    data(){
        return{
            loading: false,
            user: {},
            etiquetas: [],
            lector: [],
            responsable: [],
        }
    },
    mounted(){
        this.getDataUsuario()
    },
    methods: {
        eliminar(){
            this.$refs.eliminarUsuario.toggle();
        },
        async deleteUsuario(id_user){
            try {
                const { data } = await usuarios.deleteUsuario(id_user)
                // this.clearCurrentUser();
                this.$refs.eliminarUsuario.toggle();
                this.notificacion('', 'Eliminado correctamente', 'success')
            } catch (e){
                this.error_catch(e)
            }
        },
        formatYears(date){
            return moment().diff(date, 'years');
        },
        formatDate(date){
            return moment(date).format('DD / MM / YYYY')
        },
        async getDataUsuario(){
            try {
                let id_user = this.$route.params.id_user
                this.loading = true
                const { data } = await usuarios.userData(id_user)
                this.user = data.data.user
                this.etiquetas = data.data.etiquetas
                this.lector = data.data.lector
                this.responsable = data.data.responsable
                // this.setPropiedades()

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        editarUsuario(){
            const id = this.$route.params.id_user
            this.$store.commit('configurar/usuarios/setCurrentUser', { id })
            this.$router.push({ name: 'configurar.sistema.crear-editar' })
        }
    },
}
</script>