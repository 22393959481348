<template>
    <section>
        <header-page :title="titulo">
            <div class="col-auto text-gris f-600 f-20 ml-auto cr-pointer" @click="$router.push({name:'foro', params:{id_curso: id_curso}})">
                Foro <i class="icon-forum text-general f-20" />
            </div>
        </header-page>
        <div class="row mx-0">
            <div class="col border-right">
                <!-- Busqueda -->
                <div class="row mx-0">
                    <div class="col pt-3">
                        <el-input v-model="search" class="w-100" placeholder="Buscar" suffix-icon="icon-search text-general" />
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 268px);">
                        <div v-for="(user, idx) in filtroUsuraios" :key="idx" class="row mx-0 border-bottom py-3 cr-pointer" @click="verMensajes(user)">
                            <div class="col-auto">
                                <el-badge :value="user.mensajes_dia" :hidden="user.mensajes_dia<=0">
                                    <img :src="user.foto_firmada" class="rounded-circle obj-cover" width="62" height="62" />
                                </el-badge>
                            </div>
                            <div class="col tres-puntos">
                                <p class="tres-puntos  f-18 f-600" :class="id_user == user.id_user ? 'text-general f-600' : 'text-gris f-500'">
                                    {{ user.nombre }}
                                </p>
                                <div class="row mx-0">
                                    <p class="col pl-0 text-gris">
                                        {{ user.fecha_reciente }}
                                    </p>
                                    <p class="col pl-0 text-gris">
                                        {{ user.hora }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="id_user" class="col bg-messages px-0">
                <div class="row mx-0 bg-white py-4 px-3">
                    <div class="col-auto text-gris f-600 f-18">
                        Mensajes
                    </div>
                </div>
                <div id="scrollGeneral" class="row mx-3 border-bottom custom-scroll overflow-auto" style="height:calc(100vh - 435px);" @scroll.passive="handleScroll">
                    <div class="col px-0">
                        <button v-show="mensajes.length > 0" class="scrollToTopBtn position-absolute" @click="restablecerScroll">
                            👇
                        </button>
                        <div v-for="(msg, idx) in mensajes" :key="idx" class="row mx-3 my-3">
                            <div class="col-12 bg-white br-12 border-edit p-3">
                                <p class="message text-gris f-15 mb-2">
                                    {{ msg.mensaje }}
                                </p>
                                <div class="row mx-0 a-center pt-3">
                                    <div class="col d-flex">
                                        <img :src="msg.creador.imagen" width="55" height="55" class="position-absolute obj-cover rounded-circle border-grey" style="top:-13px;left:5px;" />
                                        <div class="ticket-name py-1 pr-2 pl-5 tres-puntos text-center" style="min-width:170px;">
                                            {{ msg.creador.nombre }}
                                        </div>
                                    </div>
                                    <div class="col text-right f-15">
                                        {{ formatearFecha(msg.created_at, 'D/M/Y HH:mm A') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <infinite-loading ref="infiniteLoading" direction="top" @infinite="listarMensajes">
                            <div slot="spinner">
                                <div>
                                    Cargando...
                                </div>
                            </div>
                            <div slot="no-more" class="text-muted">
                                <span v-if="true">No hay más mensajes</span>
                            </div>
                            <div slot="no-results" class="text-muted">
                                <span v-if="mensajes.length <=0">
                                    No se encuentran mensajes
                                </span>
                            </div>
                        </infinite-loading>
                    </div>
                </div>
                <!-- Button Answer -->

                <ValidationObserver ref="validacion">
                    <div class="row mx-0 py-3">
                        <div class="col-12 position-relative">
                            <i v-if="model.mensaje !== ''" class="icon-send text-general f-23 position-absolute z-1 cr-pointer" style="top:40px;right:15px;" @click="guardarMensaje" />
                            <ValidationProvider v-slot="{errors}" rules="required|max:500" name="comentario">
                                <el-input v-model="model.mensaje" class="w-100 input-chat " placeholder="Comentar " maxlength="501" type="textarea" :rows="5" @keydown.native="detectarEnter($event)" />
                                <span class="text-danger w-100 f-12"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
            <div v-if="!id_user" class="col bg-messages px-0 text-center py-2">
                Seleccione un usuario
            </div>
        </div>
    </section>
</template>

<script>
import MensajesService from '~/services/mensajes/mensajes'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
export default {
    components: {
        InfiniteLoading,
    },
    sockets:{
        learning_cursos_mensajes(value){ 
            try {
                const {mensaje} = JSON.parse(value)
                this.actualizarMenuLateral(mensaje)

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    data(){
        return {
            titulo: '',
            id_curso: parseInt(this.$route.params.id_curso),
            search: null,
            id_user: null,
            usuarios:[],
            mensajes:[],
            page:1,
            nameCanal: 'learning_cursos_mensajes',
            model:{
                mensaje: '',
                id_curso: null,
                id_user: null
            },
            $stateInfite: null
        }
    },
    computed:{
        filtroUsuraios(){
            if(this.search){
                return this.usuarios.filter(e => e.nombre.toLowerCase().includes(this.search.toLowerCase()))
            }else{
                return this.usuarios
            }

        }
    },
    mounted(){
        this.listarUsuarios()
    },
    methods:{
        async listarUsuarios(){
            try {
                const {data:{data}} = await MensajesService.listarUsuariosMensajes({id_curso: this.id_curso})
                this.usuarios = data.usuarios
                this.titulo = `Mensajes Curso ${data.curso.nombre}`
            } catch (e){
                this.error_catch(e)
            }
        },
        async verMensajes(user){
            // var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
            // console.log('verMensajes', scrollToTopBtn);
            // this.ocultarIcono()
            this.id_user = user.id_user
            this.mensajes = []
            if(this.$refs.infiniteLoading){
                this.page = 1                
                this.$refs.infiniteLoading.stateChanger.reset(); 
            }
            this.subscribeChanel()

        },
        delay(ms=100){
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async listarMensajes($state= false){
            this.$stateInfite = $state
            try {

                const state = $state
                var params = {
                    page: this.page,
                    id_user: this.id_user,
                    id_curso: this.id_curso
                }
                if(!this.id_user){
                    console.log('no existe usuario');
                    if(state){
                        state.complete()
                    }
                    return false

                }
                this.loading = true
                const {data:{data}} = await MensajesService.listarMensajesUsuario(params)
                this.loading = false
                if (data.mensajes.data.length > 0){
                    if(this.page==1){
                        this.mensajes = []
                    }
                    this.page += 1;
                    // let ordenados = _.sortBy(data.chats.data, ['created_at'])
                    // this.mensajes = this.mensajes.concat( data.mensajes.data)

                    let ordenados = _.sortBy(data.mensajes.data, ['created_at'])
                    this.mensajes = ordenados.concat( this.mensajes)
                    await this.delay_scroll(100)
                    // this.restablecerScroll()
       
                    if(state){
                        state.loaded()
                    }

                } else {
                    if(state){
                        state.complete()
                    }
                }
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        delay_scroll(ms=100){
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        subscribeChanel(){
            if (this.sockets=== undefined) this.notificacion('Mensaje','EL socket no esta importado','warning')
            this.sockets.subscribe(`${this.nameCanal}_${this.id_user}`, (value)=>{
                const {mensaje} = JSON.parse(value)
                if(this.id_user=== parseInt(mensaje.id_user)){
                    this.mensajes.push(mensaje)
                    this.deslizar()
                }
            })
        },
        actualizarMenuLateral({id_user,created_at})
        {
            const index = this.usuarios.findIndex( e => e.id_user=== id_user)
            if(index >=0){
                let hora = moment(created_at).format('HH:mm A')
                let fecha = moment(created_at).format('YYYY-MM-DD')
                // console.log('format', format);
                this.usuarios[index].hora = hora
                this.usuarios[index].fecha_reciente = fecha
                
                this.usuarios[index].mensajes_dia = this.usuarios[index].mensajes_dia + 1
                
            }
        },
        async deslizar(){
            if (this.necesitaDeslizar()){
   
                await this.delay_scroll(100)
                this.restablecerScroll()
            } else {
            }
        },
        detectarEnter(key){
            let code = key.keyCode
            if(code === 13){
                this.guardarMensaje()
            }
        },
        async guardarMensaje(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'El formato del mensaje es inválido', 'warning')
                    return false
                }
                this.model.id_curso = this.id_curso
                this.model.id_user = this.id_user
                if(!this.model.id_user){
                    this.notificacion('Mensaje', 'Usuario no selecionado', 'warning')
                    return false
                }
                const {data:{data}} = await MensajesService.guardarMensaje(this.model)
                this.model.mensaje = ''
            } catch (e){
                this.error_catch(e)
            }
        } ,       
        restablecerScroll(){
            var  element = document.getElementById('scrollGeneral')
            if(element){
                if(element.scrollTo){
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth"
                    })
                }
            }
        },
        necesitaDeslizar(){
            const rootElement = document.getElementById('scrollGeneral')
            if(rootElement === undefined || rootElement === null){
                return false
            }
            var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
            return (rootElement.scrollTop / scrollTotal ) > 0.80

        },
        ocultarIcono(){
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
            if(scrollToTopBtn){
                scrollToTopBtn.classList.remove("showBtn")
            }
        },
        handleScroll(){
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
            if (this.necesitaDeslizar()){
                scrollToTopBtn.classList.remove("showBtn")
                // Hide button
            } else {
                scrollToTopBtn.classList.add("showBtn")
                // Show button
            }
        },

    }
    
}
</script>

<style lang="scss" scoped>
.bg-messages{
    background-color: #F6F6F6;
}
.border-edit{
    border: 1px solid #DFDFDF;
}
.ticket-name{
    background-color: #D9DCE7;    
    color: var(--color-grey);
    border-radius: 0px 20px 20px 0px;
}
 .scrollToTopBtn {
    background-color: #4d5d75;
    border: none;
    border-radius: 50%;
    position: fixed;
    top: 1px;
    right: 32px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    line-height: 48px;
    width: 48px;
    /* keep it at the top of everything else */
    z-index: 100;
    /* hide with opacity */
    opacity: 0;
    /* also add a translate effect */
    transform: translateY(100px);
    /* and a transition */
    transition: all .5s ease
 }
  .showBtn {
  opacity: 1;
  transform: translateY(0)
}
</style>