import axios from 'axios';

const API_PREFIX = 'configurar/usuarios';

export default {
    save: data => axios.post(`${API_PREFIX}/save`, data),
    update: (idUser, data) => axios.put(`${API_PREFIX}/update/${idUser}`, data),
    cursos: _ => axios.get(`${API_PREFIX}/cursos`),
    cursosUser: (tipo, user) => axios.get(`${API_PREFIX}/cursos-user/${tipo}/${user}`),
    saveCursos: data => axios.post(`${API_PREFIX}/save-cursos`, data),
    deleteCurso: id => axios.delete(`${API_PREFIX}/delete-curso/${id}`),
    deleteUsuario: id => axios.delete(`${API_PREFIX}/delete/${id}`),
    listUsers: _ => axios.get(`${API_PREFIX}/users`),
    userData: idUser => axios.get(`${API_PREFIX}/user-data/${idUser}`),
};

