<template>
    <section>
        <ValidationObserver ref="form" v-slot="{invalid}">
            <header-page title="Configurar Perfil">
                <div class="col-auto ml-auto">
                    <button :disabled="invalid" class="btn text-white bg-general py-2 px-5 br-12 shadow" @click="onSubmit()">
                        Guardar
                    </button>
                </div>
            </header-page>
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 180px);">
                <div class="row mx-0 h-100 py-3">
                    <div class="col border-right">
                        <div class="row mx-0 my-3 j-center">
                            <slim-cropper ref="cropper" :options="slimOptions" class="border slim-styles cr-pointer" style="" />
                        </div>
                        <div class="row mx-0 j-center">
                            <div class="col-10">
                                <label class="f-15"> Nombre </label>
                                <el-input :value="$usuario.nombre" disabled placeholder="Nombre" class="w-100 input-name" />
                            </div>
                            <div class="col-10 my-3">
                                <label class="f-15"> Fecha de nacimiento </label>
                                <el-date-picker
                                v-model="form.nacimiento"
                                format="dd / MM / yyyy"
                                value-format="yyyy-MM-dd"
                                class="w-100"
                                type="date"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col border-left">
                        <div class="row mx-0">
                            <div class="col-10 my-3">
                                <label class="f-15 mb-2 pl-3"> Cargo </label>
                                <el-input :value="$usuario.cargo" disabled class="w-100 input-name" />
                            </div>
                            <div class="col-10 my-3">
                                <label class="f-15 mb-2 pl-3"> Correo </label>
                                <el-input :value="$usuario.correo" disabled class="w-100 input-name" />
                            </div>
                            <div class="col-10 my-3">
                                <label class="f-15 mb-2 pl-3"> Fecha de vinculación </label>
                                <el-date-picker
                                v-model="form.vinculacion"
                                class="w-100"
                                type="date"
                                format="dd / MM / yyyy"
                                value-format="yyyy-MM-dd"
                                />
                            </div>
                            <div class="col-10 d-middle my-3">
                                <div class="bg-white text-gris shadow py-2 px-4 br-12 cr-pointer" @click="showPassword = !showPassword">
                                    Cambiar Contraseña
                                </div>
                            </div>
                            <template v-if="showPassword">
                                <div class="col-10 my-2">
                                    <ValidationProvider v-slot="{errors}" rules="required" vid="password" name="contraseña">
                                        <label class="pl-3 text-gris f-15 my-2"> Contraseña </label>
                                        <el-input v-model="formPass.password" class="w-100 input-name" show-password show-word-limit maxlength="200" />
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                                <div class="col-10 my-2">
                                    <ValidationProvider v-slot="{errors}" rules="required|confirmed:password" vid="password_confirmation" name="repetir contraseña">
                                        <label class="pl-3 text-gris f-15 my-2"> Repetir Contraseña </label>
                                        <el-input v-model="formPass.password_confirmation" class="w-100 input-name" show-password show-word-limit maxlength="200" />
                                        <p class="text-danger f-10">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>

import perfilRoutes from '~/services/configurar/perfil'
export default {
    layout: 'fullscreen',
    middleware:['auth'],
    data(){
        return{
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            showPassword: false,
            form: {
                vinculacion: '',
                
                nacimiento: '',
            },
            formPass: {
                password: '',
                password_confirmation: ''
            },
            loading: false
        }
    },
    async mounted(){
        this.form.nacimiento = this.$usuario.nacimiento
        this.form.vinculacion = this.$usuario.vinculacion
        if (!_.isEmpty(this.$usuario.foto)){
            await this.$store.dispatch('auth/consultarUsuario')
            this.$refs.cropper.instanciaCrop.load(this.$usuario.foto)
        } else {
            this.$refs.cropper.instanciaCrop.remove()
        }
    },

    methods: {
        async onSubmit(){
            try {
                this.loading = this.$loading({
                    lock: true,
                    text: 'Cargando',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                const valid = await this.$refs.form.validate()
                if (valid){
                    const foto = this.$refs.cropper.instanciaCrop.dataBase64.output.image
                    this.form = {...this.form, foto}
                    if (this.showPassword){
                        this.form = {...this.form, ...this.formPass}
                    }

                    const { data }  = await perfilRoutes.update(this.form)
                    if (_.get(data, 'data.updated')){
                        this.$store.dispatch('auth/consultarUsuario')
                        this.notify('Actualizado correctamente', 'success')
                        this.$router.back();
                    }
                }
            } catch (error){
                this.error_catch(error, 'form')
            } finally {
                this.loading.close()
            }


        }
    }
}
</script>

<style lang="scss" scoped>
.slim-styles{
    height:216px;
    background:#F8F9FF;
    width:216px;
    border-radius:50%;
}
</style>