<template>
    <section>
        <header-page title="Administrar Usuarios" ruta-atras="configurar" />
        <div style="height:Calc(100vh - 180px);">
            <div class="d-flex  mx-0 h-100">
                <div class="col-4 pt-3 border-right">
                    <div class="row mx-0 j-center">
                        <div class="col-auto">
                            <button class="bg-white shadow-button text-gris f-18 br-12 py-2 px-4 cr-pointer border-0" @click="clearCurrentUser">
                                Crear Usuario
                            </button>
                        </div>
                    </div>
                    <div class="row mx-1 mt-3 mb-2 border-bottom pb-2">
                        <button class="current_tab f-18 border-0 bg-white  text-gris mr-4 " :class="{'f-600':isSelected === 1}" style="outline: 0" @click="seleccionarComponente('Elearning')">
                            E-learning
                        </button>
                        <!-- <button class="current_tab f-18  border-0 bg-white  text-gris " :class="{'f-600':isSelected === 2}" style="outline: 0" @click="seleccionarComponente('Mac')">
                            MAC
                        </button> -->
                    </div>
                    <component :is="componenteDinamico" />
                </div>
                <router-view :key="$route.fullPath" />
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment'
export default {
    components:{
        Elearning : () => import ('./eLearning'),
        Mac : () => import ('./mac')
    },
    layout: 'fullscreen',
    data(){
        return {
            loading: false,
            componenteDinamico:'Elearning',
            isSelected : null
        }
    },
    methods: {
        seleccionarComponente(componente){
            if(componente === 'Elearning'){
                this.isSelected = 1
                this.componenteDinamico="Elearning"
            }else if(componente === 'Mac'){
                this.isSelected = 2
                this.componenteDinamico="Mac"
            }
        },
        clearCurrentUser(){
            this.$store.commit('configurar/usuarios/setCurrentUser', {})
            this.$router.push({name: 'configurar.sistema.crear-editar'})
        }
    }
}
</script>

<style lang="scss" scoped>
.shadow-button{ box-shadow: 0px 3px 6px #0000001F }
.border-edit{ border: 1px solid #DFDFDF !important; }
</style>