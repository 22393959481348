<template>
    <modal ref="modalVincular" titulo="Vincular usuario" @guardar="vincularUsuario">
        <div class="col-10 mx-auto">
            <p class="f-16">Vincular con</p>
            <div class="row">
                <el-select  
                v-model="value"
                placeholder="Seleccionar"
                class="w-100"
                >
                    <el-option
                    v-for="(user, index) in users" 
                    :key="index"
                    :label="user.nombre"
                    :value="user.id"
                    class="my-1"
                    >
                        <img :src="user.image" alt="user-img" width="30" height="30" class="rounded-circle obj-cover mr-4" />
                        <span class="text-gris"> {{ user.nombre }}</span>
                    </el-option>
                </el-select>   
            </div>
            <div class="row text-align-startp-3 br-10 mt-4 p-3" style="background: #F3F4F7">
                <div class="col-2 mr-1">
                    <figure 
                    width="40" 
                    height="40" 
                    class="rounded-circle "
                    >
                        <img 
                        :src="userSelecionado.image" 
                        alt="img-user"
                        width="38"
                        height="38"
                        class="rounded-circle obj-cover"
                        />
                    </figure>
                </div>
                <div class="col">
                    <p class="f-600 f-18">{{ userSelecionado.nombre }}</p>
                    <p class="f-400 f-16">{{ userSelecionado.cargo }}</p>
                    <p class="f-400 f-14">{{ userSelecionado.edad }} años</p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return{
            value:'',
            users:[
                {   
                    id: 12,
                    nombre: 'Antoni Guerrero',
                    cargo:'Admin',
                    edad: 34,
                    image:'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6'

                },
                {   
                    id: 13,
                    nombre: 'Antoni Guerrero',
                    cargo:'Admin',
                    edad: 34,
                    image:'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6'

                },
                {   
                    id: 14,
                    nombre: 'Antoni Guerrero',
                    cargo:'Admin',
                    edad: 34,
                    image:'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6'

                },
                {   
                    id: 15,
                    nombre: 'Antoni Guerrero',
                    cargo:'Admin',
                    edad: 34,
                    image:'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6'

                }
            ],
            userSelecionado:
            {
                id: 13,
                nombre: 'Antoni Guerrero',
                cargo:'Admin',
                edad: 34,
                image:'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6'

            }
        }
    },
    methods:{
        vincularUsuario(){
            this.$emit('VincularUsuario',this.userSelecionado)
            this.$refs.modalVincular.toggle()
        },
        toggle(){
            this.$refs.modalVincular.toggle()
        }
    }
}
</script>