<template>
    <section>
        <div class="row mx-0 justify-content-center pt-5">
            <router-link v-for="(ruta, r) in menus" :key="r" :to="{name: ruta.name}">
                <div class="card-configurar border mx-3 text-center d-flex flex-column justify-content-center cr-pointer">
                    <img :src="`/img/${ruta.image}.svg`" style="max-width:100%;height:100px;" />
                    <p class="text-general f-20 f-600 my-3 px-3">
                        {{ ruta.titulo }}
                    </p>
                </div>
            </router-link>
        </div>
    </section>
</template>

<script>
export default {
    middleware:['auth'],
    data(){
        return {
            conf_tabs: {
                '20':[
                    {
                        titulo: 'Configurar Sistema',
                        image: 'conf_sistem',
                        name: 'configurar.sistema'
                    },
                    {
                        titulo: 'Configurar Cursos',
                        image: 'conf_cursos',
                        name: 'configurar.cursos'
                    }
                ],
                '21':[
                    {
                        titulo: 'Configurar Sistema',
                        image: 'conf_sistem',
                        name: 'configurar.sistema'
                    },
                    {
                        titulo: 'Administrar Usuario',
                        image: 'conf_user',
                        name: 'configurar.usuarios'
                    },
                    {
                        titulo: 'Configurar Cursos',
                        image: 'conf_cursos',
                        name: 'configurar.cursos'
                    }
                ]
            }
        }
    },
    computed:{
        menus(){
            return this.conf_tabs[this.$usuario.rol.toString()+this.$usuario.permiso_usuarios.toString()]
        }
    }
}
</script>

<style lang="scss" scoped>
.card-configurar{
    width: 190px;
    height: 250px;
    background: #FFF;
    border-radius: 12px !important;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
