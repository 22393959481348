import axios from 'axios';

const API_PREFIX = 'configurar/etiquetas';

const ENDPOINTS = {
    etiquetas: () => axios.get(`${API_PREFIX}/etiquetas`),
    etiquetasNombre: (params) => axios.get(`${API_PREFIX}/etiquetas-por-nombre`, {params}),
    etiquetaUsuario: (idUser) => axios.get(`${API_PREFIX}/etiqueta-usuario/${idUser}`),
};

export default ENDPOINTS;
